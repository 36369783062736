import axios from 'axios'
import {getToken} from "@/utils/auth" 
import {
    Message
} from 'element-ui'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // Do something before request is sent
        if (getToken()) {
            // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
            config.headers.Token = getToken()
        }
        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        const res = error.response
        const code = res.status
        if (code === 498) {
            Message.error(res.data.ErrMsg)
        } else {
            Message.error('网络错误,请稍候尝试!')
        }
        return Promise.reject(error)
    }
)

export default service
